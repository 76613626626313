.error-main-container-not-found{

    width: 100vw;
    height: calc(100vh - 80px);
    background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692786367/Error/Error2_l6lq20.webp");
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;

}

@media only screen and (max-width: 425px) {

    .error-main-container-not-found{

        background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692786507/Error/Error-mobile_firn79.webp");

    }
}