.container-1 {
  height: fit-content;
  width: 100vw;
  background-image: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 3%;
  padding-left: 50px;
  gap: 20px;
  background-color: #693897;
}

.container-1.bg-loaded {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437074/HomePagePictures/DigitalMarketingInUsa/loudspeaker3_nyn0un.webp");
}

.container-1 h1 {
  color: #fff;
  font-size: 3rem;
  width: 50%;
}

.container-1 p {
  color: #fff;
  width: 50%;
  line-height: 1.5;
  font-size: 1.1rem;
  text-align: justify;
}

.container-1 button {
  background-color: #fff;
  font-size: 1rem;
  color: #693897;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  padding: 1% 1%;
  margin-top: 1%;
  transition: all 0.25s;
  border: none;
}

.container-1 button:hover {
  cursor: pointer;
  scale: 1.2;
  box-shadow: 0 0 10px 5px #d9d9d9;
}

.digital-marketing-container-2 {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437073/HomePagePictures/DigitalMarketingInUsa/loudspeaker4_bsfw6t.webp");
  background-size: 100% 100%;
  height: 10vh;
}

.casestudy-container {
  width: 100vw;
}

.casestudy-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 50px;
}

.casestudy-container h2 {
  font-size: xx-large;
  border-bottom: 5px solid #693897;
}

.casestudy-container h3 {
  color: #693897;
}

.casestudy-container h5 {
  color: #693897;
}

.casestudy-container h1 {
  font-size: xx-large;
}

@media (max-width: 767px) {
  .container-1 {
    height: 25vh;
    width: 100vw;
    background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437074/HomePagePictures/DigitalMarketingInUsa/loudspeaker3_nyn0un.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 3%;
    padding-left: 25px;
    gap: 4%;
    background-color: #693897;
  }

  .container-1 h1 {
    color: #fff;
    font-size: 1rem;
  }

  .container-1 p {
    color: #fff;
    width: 50%;
    line-height: 1.5;
    font-size: 0.5rem;
  }

  .container-1 button {
    background-color: #fff;
    font-size: 0.4rem;
    font-weight: bold;
    color: #693897;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    padding: 2% 2%;
    transition: all 0.25s;
    border: none;
  }

  .digital-marketing-container-2 {
    height: 5vh;
  }

  .casestudy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 25px;
    gap: 2vh;
  }
}

@media (max-width: 430px) {
  .container-1 {
    height: 40vh;
  }

  .container-1 p {
    width: 55%;
    font-size: 0.65rem;
  }

  .container-1 button {
    font-size: 0.7rem;
  }
}

@media (max-width: 375px) {
  .container-1 {
    height: 40vh;
  }

  .container-1 p {
    width: 57%;
    font-size: 0.625rem;
  }

  .container-1 button {
    font-size: 0.7rem;
  }
}

@media (max-width: 350px) {
  .container-1 {
    height: 40vh;
  }

  .container-1 p {
    width: 57%;
    font-size: 0.625rem;
  }

  .container-1 button {
    font-size: 0.7rem;
  }
}
