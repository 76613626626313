.card1-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
  padding: 2.5% 5%;
  gap: 2rem;
}

.card1-main-container-one {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 12.5%;
  flex-wrap: wrap;
}

.card1-main-container-one-card1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 3% 5%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.card1-main-container-one-card1:hover {
  background-color: #612d91;
  color: #fff;
  cursor: pointer;
}

.card1-main-container-one-card1 h2 {
  text-align: center;
  align-self: stretch;
}

.card1-main-container-one-card1 p {
  text-align: justify;
  line-height: 1.5;
  align-self: stretch;
}

.card1-main-container-one-card1 img {
  width: 20%;
  align-self: center;
}

.card1-main-container-one-card1 picture{
  display: flex;
  justify-content: center;
}

.card1-main-container-one-card2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 3% 5%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.card1-main-container-one-card2:hover {
  background-color: #612d91;
  color: #fff;
  cursor: pointer;
}

.card1-main-container-one-card2 h2 {
  text-align: center;
}

.card1-main-container-one-card2 p {
  text-align: justify;
  line-height: 1.5;
}

.card1-main-container-one-card2 img {
  width: 20%;
  align-self: center;
}

.card1-main-container-one-card2 picture{
  display: flex;
  justify-content: center;
}

.card1-main-container-two {
  flex: 1;
  display: flex;
  gap: 2rem;
  padding: 0 12.5%;
  flex-wrap: wrap;
}

.card1-main-container-two-card3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 3% 5%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.card1-main-container-two-card3:hover {
  background-color: #612d91;
  color: #fff;
}

.card1-main-container-two-card3 h2 {
  text-align: center;
}

.card1-main-container-two-card3 p {
  text-align: justify;
  line-height: 1.5;
}

.card1-main-container-two-card3 img {
  width: 20%;
  align-self: center;
}

.card1-main-container-two-card3 picture{
  display: flex;
  justify-content: center;
}

.card1-main-container-two-card4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 3% 5%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.card1-main-container-two-card4:hover {
  background-color: #612d91;
  color: #fff;
  cursor: pointer;
}

.card1-main-container-two-card4 h2 {
  text-align: center;
}

.card1-main-container-two-card4 p {
  text-align: justify;
  line-height: 1.5;
}

.card1-main-container-two-card4 img {
  width: 20%;
  align-self: center;
}

.card1-main-container-two-card4 picture{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 920px) {
  .card1-main-container {
    padding: 2.5% 0%;
    gap: 2rem;
  }

  .card1-main-container-one {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-one-card1 {
    padding: 3% 4%;
  }

  .card1-main-container-one-card2 {
    padding: 3% 4%;
  }

  .card1-main-container-two {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-two-card3 {
    padding: 3% 4%;
  }

  .card1-main-container-two-card4 {
    padding: 3% 4%;
  }
}

@media only screen and (max-width: 768px) {
  .card1-main-container {
    padding: 2.5% 0%;
    gap: 2rem;
  }

  .card1-main-container-one {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-one-card1 {
    padding: 3% 4%;
  }

  .card1-main-container-one-card2 {
    padding: 3% 4%;
  }

  .card1-main-container-two {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-two-card3 {
    padding: 3% 4%;
  }

  .card1-main-container-two-card4 {
    padding: 3% 4%;
  }
}

@media only screen and (max-width: 425px) {
  .card1-main-container {
    padding: 7.5% 2%;
    gap: 2rem;
  }

  .card1-main-container-one {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-one-card1 {
    padding: 3% 4%;
  }

  .card1-main-container-one-card2 {
    padding: 3% 4%;
  }

  .card1-main-container-two {
    gap: 2rem;
    padding: 0 4%;
  }

  .card1-main-container-two-card3 {
    padding: 3% 4%;
  }

  .card1-main-container-two-card4 {
    padding: 3% 4%;
  }
}

@media only screen and (max-width: 320px) {
  .card1-main-container-one-card1 {
    padding: 4% 4%;
  }

  .card1-main-container-one-card1 h2 {
    font-size: large;
  }

  .card1-main-container-one-card1 p {
    line-height: 1.4;
    font-size: 15px;
  }

  .card1-main-container-one-card2 {
    padding: 4% 4%;
  }

  .card1-main-container-one-card2 h2 {
    font-size: large;
  }

  .card1-main-container-one-card2 p {
    line-height: 1.4;
    font-size: 15px;
  }

  .card1-main-container-two-card3 {
    padding: 4% 4%;
  }

  .card1-main-container-one-card3 h2 {
    font-size: large;
  }

  .card1-main-container-one-card3 p {
    line-height: 1.4;
    font-size: 15px;
  }

  .card1-main-container-two-card4 {
    padding: 4% 4%;
  }

  .card1-main-container-one-card4 h2 {
    font-size: large;
  }

  .card1-main-container-one-card4 p {
    line-height: 1.4;
    font-size: 15px;
  }
}
