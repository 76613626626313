.main-services-container {
  padding: 0 30px;
}

.main-services-container h1 {
  text-align: center;
  font-size: 2.5rem;
}

.main-services-container-description {
  text-align: center;
  font-size: 1.1rem;
}

.services1-card-container {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
  width: 100%;
  /* padding: 0 50px; */
}

.services1-card-container-one {
  flex: 1;
  display: flex;
  gap: 2.5%;
  flex-wrap: wrap;
}

.vision-mission {
  flex: 1;
  min-height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.vision-mission.vision-mission-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435171/HomePagePictures/OurMission/Vision-Mission-Light_vgt77p.webp");
  /* Other styles */
}

.vision-mission:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435171/HomePagePictures/OurMission/Vision-Mission-Dark_v5gxuh.webp");
}

.vision-mission h3 {
  text-align: center;
  color: #fff;
}

.vision-mission p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
}

.solution {
  flex: 1;
  min-height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.solution.solution-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435515/HomePagePictures/OurMission/solution_hwsd33.webp");
  /* Other styles */
}

.solution:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435523/HomePagePictures/OurMission/solution-dark_3_mmuf6m.webp");
}

.solution p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.solution h3 {
  text-align: center;
  color: #fff;
}

.design {
  flex: 1;
  min-height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.design.design-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435920/HomePagePictures/OurMission/design_ztmpdj.webp");
  /* Other styles */
}

.design:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435933/HomePagePictures/OurMission/design-dark_gn5wxk.webp");
}

.design p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.design h3 {
  text-align: center;
  color: #fff;
}

.branding {
  flex: 1;
  min-height: 30vh;
  /* width: 33.33%; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.branding:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436393/HomePagePictures/OurMission/branding-dark_ipgniz.webp");
}

.branding p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.branding h3 {
  text-align: center;
  color: #fff;
}

.marketing {
  flex: 1;
  /* width: 33.33%; */
  min-height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.marketing.marketing-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436605/HomePagePictures/OurMission/marketing_dtepd2.webp");
  /* Other styles */
}

.marketing:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436576/HomePagePictures/OurMission/marketing-dark_1_vllmfp.webp");
}

.marketing p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.marketing h3 {
  text-align: center;
  color: #fff;
}

.sales {
  /* flex: 1; */
  /* width: 33.33%; */
  min-height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
  transition: all 0.5s;
}

.sales.sales-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436828/HomePagePictures/OurMission/sales_vhhmbb.webp");
  /* Other styles */
}

.sales:hover {
  scale: 1.1;
  cursor: pointer;
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436824/HomePagePictures/OurMission/sales-dark_1_wihkjg.webp");
  background-size: auto;
}
.sales p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.sales h3 {
  text-align: center;
  color: #fff;
}

.services1-card-container-two {
  flex: 1;
  display: flex;
  gap: 2.5%;
}

.branding {
  flex: 1;
  height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
}

.branding.branding-background {
  background-image: url("https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436253/HomePagePictures/OurMission/background_pu2kfm.webp");
  /* Other styles */
}


.branding p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.marketing {
  flex: 1;
  height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0 2.5%;
}

.marketing p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

.sales {
  flex: 1;
  height: 30vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 2.5%;
}

.sales p {
  text-align: justify;
  color: #fff;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .services1-card-container-one {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    flex-wrap: wrap;
  }

  .services1-card-container-two {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    flex-wrap: wrap;
  }
}
