@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    .contact-form-slanted-div-one {
 
        clip-path: polygon(0 0, 100% 0%, 47% 100%, 0% 100%);
 
    }
    
    .contact-form-slanted-div-two {
        clip-path: polygon(41% 0, 100% 0%, 100% 100%, 0 100%);
    }

    .contact-form-triangle {
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }

    .contact-form-triangle-two {
        clip-path: polygon(100% 0, 0 0, 100% 100%);
    }
 
 }