.star {
  display: inline-block;
}

.star i {
  color: #ccc;
}

.star.filled i {
  color: #612D91;
}
