.home-skills-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3% 0;
  background-color: #e5e5e5;
}

.home-skills-1 {
  /* width: 50%; */
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
}

.home-skills-1 p{
  line-height: 1.5;
  text-align: justify;
}


.home-skills-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self:auto;
  padding: 0 25px;
  flex: 1;
  gap: 30px;
}

.home-skills-1 h1 {
  font-size: 2.5rem;
}

.home-skills-1 button {
  width: 20%;
  height: 30px;
  border-radius: 20px;
  margin-top: 10px;
  background-color: #612d91;
  color: #fff;
  border: none;
}

.home-skills-1 a {
  width: fit-content;
  height: 30px;
  border-radius: 20px;
  padding: 2vh 4vw;
  margin-top: 10px;
  background-color: #612d91;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: medium;
}

.home-progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  overflow: hidden;
}

.home-progress-bar {
  height: 100%;
  color: #f2f2f2;
  background-color: #612D91;
  transition: width 0.1s linear;
  text-align: right;
}

@media (max-width: 767px) {
  .home-skills-container {
    gap: 4vh;
    flex-direction: column;
  }
}