@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.summary-main-container {
  padding: 0 50px;
}

.summary-main-container h1 {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  font-size: 2rem;
  font-display: swap;
  color: #101629;
}

.summary-main-container p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-display: swap;
  font-weight: lighter;
  font-size: 1rem;
  color: #000000;
  line-height: 1.75;
}

.summary-main-container-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.summary-main-container-2a {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.summary-main-container-2b {
  
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;

}

.summary-main-container-2b img {

  width: 500px;
  align-self: center;

}

/* Tablets (768px to 1023px) */
@media (min-width: 768px) and (max-width: 923px) {

  /* CSS styles specific to tablets */
  .summary-main-container-2b img {
    width: 100vw;
    align-self: center;
    padding: 0 -50px;
  }

}

@media (max-width: 767px) {

  /* CSS styles specific to mobile devices */
  .summary-main-container-2b img {
    width: 100%  ;
    align-self: center;
    padding: 0 -50px;
  }

  .summary-main-container-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

}

@media (max-width: 375px) {

  .summary-main-container {
    padding: 0 30px;
  }

}