@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

:root {
  --mainColor: #fff;
  --mainColorLight: #612d91;
  --secondaryLight: #612d91;
  --textColor: #000;
  overflow-x: hidden;
}

.navbar-main-container-one{
  position: sticky;
  top: 0;
  z-index: 21;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  position: sticky;
  top: 0;
  z-index: 20;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav a {
  margin: 0 2rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryLight);
  cursor: pointer;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.contact-button {
  background-color: #fff;
  border: 1px solid #612d91;
  width: 12vw;
  height: 6vh;
  border-radius: 5px;
}

.contact-button a{

  text-decoration: none;
  color: #000;

}

.contact-button:hover {
  cursor: pointer;
}

.logo {
  width: 160px;
  height: 60px; 
  display: flex;
}

.logo img {
  width: 100%;
  height: 100%;
  align-self: center;
}

.home {
  text-decoration: none;
  border-top: 1px solid transparent;
  transition: border-top 0.7s linear;
}

.home:hover {
  border-top: 1px solid #612d91;
}

.about {
  text-decoration: none;
  border-top: 1px solid transparent;
  transition: border-top 0.7s linear;
}

.about:hover {
  border-top: 1px solid #612d91;
}

.services {
  text-decoration: none;
  border-top: 1px solid transparent;
  transition: border-top 0.7s linear;
  margin-right: 0.2rem;
  display: flex;
  align-items: center;
}

.services:hover {
  border-top: 1px solid #612d91;
}

.drop-down {
  vertical-align: middle;
  margin-left: 4px;
}

.portfolio {
  text-decoration: none;
  border-top: 1px solid transparent;
  transition: border-top 0.7s linear;
}

.portfolio:hover {
  border-top: 1px solid #612d91;
}

.contact {
  text-decoration: none;
  border-top: 1px solid transparent;
  transition: border-top 0.7s linear;
}

.contact:hover {
  border-top: 1px solid #612d91;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}

.dropdown.open {
  display: block;
}

.navbar-drop-down-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position:absolute;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}

.navbar-drop-down-container-column-one{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 3vh;
}

.navbar-drop-down-container-column-one a{
  text-decoration: none;
}

.navbar-drop-down-container-column-one h3{
  margin-bottom: 1vh;
  color: #612d91;
} 

.sub-category-one{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-one p{
  color: #612d91;
}

.sub-category-two{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-two p{
  color: #612d91;
}

.sub-category-three{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-three p{
  color: #612d91;
}

.sub-category-four{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-four p{
  color: #612d91;
}

.navbar-drop-down-container-column-two{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 3vh;
}

.navbar-drop-down-container-column-two a{
  text-decoration: none;
}

.navbar-drop-down-container-column-two h3{
  margin-bottom: 1vh;
  color: #612d91;
} 

.sub-category-five{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 3%;
}

.sub-category-five p{
  color: #612d91;
}

.sub-category-six{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-six p{
  color: #612d91;
}

.sub-category-seven{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}


.sub-category-seven p{
  color: #612d91;
}

.navbar-drop-down-container-column-three{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 3vh;
}

.navbar-drop-down-container-column-three a{
  text-decoration: none;
}

.navbar-drop-down-container-column-three h3{
  margin-bottom: 1vh;
  color: #612d91;
}

.sub-category-eight{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}


.sub-category-eight p{
  color: #612d91;
}

.sub-category-nine{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-nine p{
  color: #612d91;
}

.sub-category-ten{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-ten p{
  color: #612d91;
}

.sub-category-eleven{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 3%;
}

.sub-category-eleven p{
  color: #612d91;
}

.sub-category-twelve{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-twelve p{
  color: #612d91;
}

.navbar-drop-down-container-column-four{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 3vh;
}

.navbar-drop-down-container-column-four a{
  text-decoration: none;
}

.navbar-drop-down-container-column-four h3{
  margin-bottom: 1vh;
  color: #612d91;
}

.sub-category-thirteen{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-thirteen p{
  color: #612d91;
}

.sub-category-fourteen{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
}

.sub-category-fourteen p{
  color: #612d91;
}

.sub-category-fifteen{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 3%;
}

.sub-category-fifteen p{
  color: #612d91;
}

.all-mobile-categories{
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
  margin-top: 1vh;
}

.mobile-category-one{
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.mobile-category-one-heading{
  margin: 0;
}

.mobile-category-one-sub-categories{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  max-height: 0;
  overflow: hidden;
}

.slide-first-dropdown-from-top {
  max-height: 0;
  transition: max-height 1.25s ease;
}

.slide-first-dropdown-from-top.open {
  max-height: 100vh;
}

.mobile-category-two{
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.mobile-category-two-heading{
  margin: 0;
}

.mobile-category-two-sub-categories{
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.mobile-category-three{
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.mobile-category-three-heading{
  margin: 0;
}

.mobile-category-three-sub-categories{
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.mobile-category-four{
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.mobile-category-four-sub-categories{
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

@media only screen and (max-width: 1024px) {

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 90%;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    /* transform: translateY(-100vh); */
    transform: translateX(100vw);
    padding-top: 7.5rem;
    padding-bottom: 3vh;
    overflow-y: scroll;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.75vh;
  }

  .contact-button {
    display: none;
  }

  .logo {
    width: 190px;
    height: 60px;
  }

  .contact-button-container{

    display: none;
  }
}


@media only screen and (max-width: 425px) {

  header {
    
    padding: 0 15px;

  }
  
}