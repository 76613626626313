.pricing-two-main-container-card-all-pointer::-webkit-scrollbar {
  width: 12px;
}

.pricing-two-main-container-card-all-pointer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.pricing-two-main-container-card-all-pointer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #612d91;
}

.pricing-two-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  padding: 0 30px;
}

.pricing-two-main-container-card {
  width: 30vw;
  height: 95vh;
  padding: 5vh 0vw;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  border: 1px solid gray;
}

.recommended {
  background-color: #612d91;
  color: #fff;
}

.recommended .card-pointer {
  color: #fff;
}

.recommended .pricing-two-main-container-card-all-pointer::-webkit-scrollbar {
  width: 12px;
}

.recommended .pricing-two-main-container-card-all-pointer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.recommended .pricing-two-main-container-card-all-pointer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: inset 0 0 6px #fff;
}

.pricing-two-main-container-card-all-pointer {
  height: 55vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 3vw;
}

.pricing-two-main-container-card:hover {
  background-color: #612d91;
  color: #fff;
  cursor: pointer;
}

.pricing-two-main-container-card:hover
  .pricing-two-main-container-card-all-pointer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px #fff;
}

.card-pointer {
  color: #612d91;
}

.pricing-two-main-container-card:hover .card-pointer {
  color: #fff;
}

.pricing-two-main-container-card-pointer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 5px;
  margin-bottom: 2vh;
}

.pricing-two-main-container-card h1 {
  font-size: xx-large;
  align-self: center;
}

.pricing-two-main-container-card h4 {
  font-size: larger;
  align-self: center;
  margin-bottom: 4vh;
}

.pricing-two-main-container-card span {
  font-size: large;
}

.pricing-two-main-container-card button {
  position: absolute;
  bottom: 5vh;
  color: #612d91;
  border: 1px solid #612d91;
  background-color: #fff;
  padding: 2vh 3vw;
  font-size: medium;
  left: 50%;
  transform: translateX(-50%);
}

.pricing-two-main-container-card button:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .pricing-two-main-container {
    flex-direction: column;
  }

  .pricing-two-main-container-card {
    width: 80vw;
    height: 75vh;
  }

  .pricing-two-main-container-card-all-pointer {
    height: 40vh;
  }
}

@media (max-width: 425px) {
  .pricing-two-main-container {
    gap: 4vh;
    padding: 0 20px;
  }

  .pricing-two-main-container-card {
    width: 80vw;
    height: 60vh;
  }

  .pricing-two-main-container-card-all-pointer {
    height: 20vh;
    font-size: x-small;
  }
}

@media (max-width: 320px) {
  /* .pricing-two-main-container-card {
        width: 80vw;
        height: 85vh;
    } */

  .pricing-two-main-container-card {
    width: 80vw;
    height: 75vh;
  }

  .pricing-two-main-container-card-all-pointer {
    height: 35vh;
    font-size: 5px;
  }

  .pricing-two-main-container-card h1 {
    font-size: x-large;
  }

  .pricing-two-main-container-card h4 {
    font-size: medium;
  }

  .pricing-two-main-container-card span {
    font-size: medium;
  }
}
