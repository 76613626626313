@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.our-work-container-one{
  /* background-image: url("our_work_1.png");  
  background-size: 100% 100%; */
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-work-container img {
  width: 100%;
}

.our-work-container-one p {
  text-align: center;
  padding: 0 18vw;
  font-size: 1.25rem;
  line-height: 2;
}

.our-work {
  text-align: center;
  color: aqua;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  font-size: xx-large;
  font-display: swap;
}

.our {
  color: #000000;
}

.work {
  color: #000000;
}

.our-work-cards {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -2%;
  transition: all 1s ease-in;
  background-image: transparent;
  position: relative;
}

.our-work-cards:hover {
  background-image: linear-gradient(to top, #000000b0, transparent);
  cursor: pointer;
}

.our-work-cards button {
  position: absolute;
  top: 55%;
  background-color: #fff;
  border: 1px solid #612d91;
  font-size: medium;
  padding: 2.5vh 2.5vw;
  cursor: pointer;
  color: #612d91;
  opacity: 0;
  border-radius: 25px;
  transition: all 1s ease-in;
}

.our-work-cards:hover button {
  opacity: 1;
}

.our-work-cards button:hover {
  scale: 1.1;
}

@media (max-width: 767px) {
    .our-work-cards button {
        font-size: 12px;
        font-weight: bold;
    }
}

@media (max-width: 425px) {
  .our-work-container-one p {
    text-align: center;
    padding: 0 9vw;
    font-size: 1rem;
  }

  .our-work-cards {
    height: 200px;
  }
  
}

@media (max-width: 375px) {

  .our-work-cards {
    height: 180px;
  }
  
}

@media (max-width: 320px) {

  .our-work-cards {
    height: 150px;
  }
  
}