.video-background {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px); /* Adjust the height as needed */
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 5vw;
}

#my-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
  /* Add styles for your content */
}

.content h1 {
  color: #fff;
  font-size: 40px;
}

.content h2 {
  color: #fff;
  font-size: x-large;
  font-weight: 300;
  line-height: 1.25;
}

.content button {
  background-color: #612d91;
  color: #fff;
  border: none;
  width: fit-content;
  padding: 2vh 1.5vw;
  font-size: large;
  cursor: pointer;
  border-radius: 99999999px;
}

.content a {
  color: #fff;
  text-decoration: none;
  width: fit-content;
}

@media only screen and (max-width: 425px) {

  .content h1 {
    font-size: 40px;
  }

  .content h2 {
    font-size: x-large;
  }

  .content button {
    padding: 2vh 2.5vw;
    font-size: large;
  }
}
