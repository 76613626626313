.footer-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
}

.footer-1 {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  padding: 5% 25px;
  gap: 4%;
  background-color: #dcdcdc;
}

.footer-2 {
  width: 100%;
  height: fit-content;
  background-color: #612d91;
  color: #fff;
  padding: 10px 25px;
  display: flex;
  flex-wrap: wrap;
}

.about-company {
  flex: 1;
  flex-grow: 1;
}

.about-company p {
  text-align: left;
  font-size: small;
  font-weight: light;
  line-height: 2;
}

.about-company img {
  width: 30%;
  max-width: 200px;
}

.cities {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
}

.enquiry {
  flex: 1;
  flex-grow: 1;
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  justify-content: space-around;
}

.all-cities {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 4;
  line-height: 2;
  gap: 2%;
  font-size: small;
}

.all-cities a {
  text-decoration: none;
  color: black;
}

.cith-heading {
  flex: 1;
}

.enquiry1 {
  flex: 1;
}

.enquiry1 button {
  width: 35%;
  height: 80%;
  background-color: #612d91;
  color: #fff;
}

.enquiry2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: baseline;
}


.enquiry3 {
  flex: 1;
}

.email {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.email p {
  margin-left: 15px;
  font-size: 1rem ;
}

.phone {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.phone p {
  margin-left: 15px;
  font-size: 1rem;
}

.location {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.location p {
  margin-left: 15px;
  font-size: 1rem;
}

.icons-container {
  display: flex;
  flex-direction: row;
  width: 40%;
  gap: 5px;
}

@media (max-width: 950px) {
  .footer-1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* padding-left: 25px; */
    padding: 5% 30px;
    gap: 3vh;
    background-color: #dcdcdc;
  }

  .about-company p {
    font-size: medium;
  }

  .all-cities p{
    font-size: medium;
  }

  .enquiry {
    padding-left: 0;
  }

  /*.all-cities{

    flex-direction: column;

  }

  .all-cities div{

    display: flex;
    flex-direction: row;
    
  }

  .all-cities p{

    width:20%

  } */

  .enquiry2 {
    gap: 2vh;
  }
}

@media (max-width: 425px) {

  .about-company p {
    font-size: small;
  }

  .all-cities p{
    font-size: small;
  }

}