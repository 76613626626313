.carousel-container-item{
  border: 1px solid #612d91;
  padding: 2.5% 10%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.carousel-container-item h1 {
  text-align: center;
  font-size: 2rem;
  margin: auto;
}

.carousel-container-item p {
  text-align: center;
  font-size: 1rem;
}

.slick-dots li.slick-active button:before {
  color: #612d91;
}
